/** 
 *   订单
 */
import { get, post } from '@/untils/js/axios.js'

// 获取订单
export const getOrder = params => get('/order/list', params)

// 获取售后订单
export const getAftersale = params => get('/aftersale/list', params)

// 取消订单
export const cancelOrder = params => post(`/order/cancel/${params}`)

// 订单为您推荐
export const orderRecommend = params => get('/orderlist_recommend', params)

// 获取订单详情
export const getOrderDetail = params => get(`/order/details/${params.id}`)


// 获取订单详情
export const getSelfInfo = () => get(`/order/pick-up-info`)

// 重新加入购物车
export const addCart = params => post(`/order/add-cart`,params)

// 获取继续支付订单号
export const getContinuePayTitle = id => post(`/order/continue-topay/${id}`)


// 获取是否继续申请的状态
export const applyFor = params => post(`/aftersale/is-applyfor`, params)


// 提交评论
export const submitCommen = params => post(`/order/comment`, params)

// 评价列表
export const comment_list = params => get(`/order/comment`, params)

// 评价详情
export const comment_detail = id=> get(`/order/comment/${id}`)

// 微信支付
export const wechatWay = params => post(`/snap/pay/h5`, params)

// 支付宝支付
export const alipayWay = params => post(`/snap/pay/h5`, params)

// 支付宝支付（新）
export const alipayWeb = params => post(`/snap/pay/aliweb`, params)

// 支付状态判断
export const checkOrderPay = params => post(`/snappay/check-result`, params)

// 支付之前点击
export const recording_pay_method = params => post('order/recording-pay-method', params)

// 二维码支付
export const pcpay = params => post('/snap/pay/qrcode',params)

// 银联支付
export const web_pay = params => post('/snap/pay/web',params)


